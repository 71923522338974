<template>
  <article class="bg-white mb-10">
    <NuxtLink :to="teaser.attributes.url" :class="!teaser.attributes.media?.imageUrl ? teaserColor ? 'bg-red-600 text-center p-24 h-full flex items-center justify-center' : 'bg-black text-center p-24 h-full flex items-center justify-center' : 'md:flex-col group'">
      <div class="relative mr-10 md:mr-0 md:mb-10 bg-grey-300 aspect-video w-full lg:min-w-[160px]" v-if="teaser.attributes.media?.imageUrl">
        <AllerImgixImg :media="teaser.attributes.media" sizes="(min-width: 768px) 294px, 160px" width="160" height="90" :loading="lazyImg" :modifiers="{ ar: '16:9' }" />
        <i v-if="teaser.attributes.icon && teaser.attributes.icon.type === 'video'" class="bg-svg-video-white bg-no-repeat bg-center bg-red-600 square-25 absolute top-0 -mt-5 ml-5"></i>
        <i v-else-if="teaser.attributes.icon && teaser.attributes.icon.type === 'gallery'" class="bg-svg-gallery-white bg-22 bg-no-repeat bg-center bg-red-600 square-25 absolute top-0 -mt-5 ml-5"></i>
      </div>
      <div class="max-w-full text-center" :class="{ 'bg-grey-200 lg:bg-white px-30 md:px-0 py-8 md:py-0 md:text-left': teaser.attributes.media?.imageUrl }">
        <div class="mb-5 lg:mb-10 flex items-center justify-center" :class="!teaser.attributes.media?.imageUrl ? 'justify-center' : 'md:justify-start'">
          <span class="font-bold uppercase mr-10" :class="!teaser.attributes.media?.imageUrl && teaserColor ? 'text-yellow-500' : 'text-red-600'">{{ teaser.type.indexOf('advertorial') > -1 ? 'Annonce' : teaser.attributes.categoryName }}</span>
          <Date class="text-14" :class="!teaser.attributes.media?.imageUrl ? 'text-white' : ''" v-if="teaser.attributes.published" :date="teaser.attributes.published" :updated-date="teaser.attributes.displayUpdatedTime ? teaser.attributes.changed : undefined" />
        </div>
        <h2 class="font-bold line-clamp-3 mx-auto" :class="!teaser.attributes.media?.imageUrl ? 'text-white text-24 lg:text-30 leading-24 lg:leading-35 font-khand' : 'group-hover:text-red-600  md:text-18 md:leading-22 leading-20'">{{ teaser.attributes.shortTitle ? teaser.attributes.shortTitle : teaser.attributes.title }}</h2>
      </div>
    </NuxtLink>
  </article>
</template>

<script setup lang="ts">
const props = defineProps({
  teaser: {
    type: Object,
    required: true
  },
  lazyImg: {
    type: String,
    default: 'lazy'
  }
});

/**
 * Get color random from composable if no teaser image
 */
const { teaserColor } = useRandom(props.teaser.id);
</script>
